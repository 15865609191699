import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import validMenu from "../../services/validMenu";
import api from "../../services/api";
import {
  SalesNav,
  SuportNav,
  FinancialNav,
  ReportsNav
} from "./NavItems";



class Nav extends Component {
  state = {
    menuSales: [],
    menuSuport: [],
    menuFinancial: [],
    menuReports: []
  };

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  componentDidMount() {
    const loadMenu = async () => {
      let userPermissions = await api.get('users/me/permissions');
      userPermissions = userPermissions.data.permissions;

      let menu = validMenu(SalesNav[0], userPermissions);
      if (menu.content.length > 0) {
        this.setState({ menuSales: [menu]} );
      }


      menu = validMenu(SuportNav[0], userPermissions);
      if (menu.content.length > 0) {
        this.setState({ menuSuport: [menu]} );
      }

      menu = validMenu(FinancialNav[0], userPermissions);
      if (menu.content.length > 0) {
        this.setState({ menuFinancial: [menu]} );
      }

      menu = validMenu(ReportsNav[0], userPermissions);
      if (menu.content.length > 0) {
        this.setState({ menuReports: [menu]} );
      }
    }

    loadMenu();
  };

  render() {
    return (
      <Fragment>
        {Object.keys(this.state.menuSales).length > 0 &&
          <MetisMenu content={this.state.menuSales} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
        }
        {Object.keys(this.state.menuFinancial).length > 0 &&
          <MetisMenu content={this.state.menuFinancial} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
        }
        {Object.keys(this.state.menuSuport).length > 0 &&
          <MetisMenu content={this.state.menuSuport} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
        }
        {Object.keys(this.state.menuReports).length > 0 &&
          <MetisMenu content={this.state.menuReports} onSelected={this.toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
        }
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
