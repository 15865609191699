import api from "./api";

export const getInfoUser = () => {
  return JSON.parse(localStorage.getItem('@userInfo'));
}

export const logout = async () => {
  let user = getInfoUser();

  if (user && window.Echo) {
    window.Echo.leave(`user_session.${user.id}`);
  }

  await api.get('logout');

  localStorage.removeItem('@userInfo');
  window.location.reload();
};
