import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter, Route, Redirect, Switch } from "react-router-dom"

import LoadingOverlay from "react-loading-overlay";
import { Loader } from "react-loaders";
import { ToastContainer } from "react-toastify";
import ResizeDetector from "react-resize-detector";
import { FormGroup, Label, Row, Col, Button } from "reactstrap";

import AppMain from "../../Layout/AppMain";
import LoginPage from "../../Pages/UserPages/Login";
import ForgotPasswordPage from "../../Pages/UserPages/ForgotPassword";
import RecoveryPasswordPage from "../../Pages/UserPages/RecoveryPassword";

import Echo from 'laravel-echo';
import api from "../../services/api";
import { getInfoUser, logout } from "../../services/auth";
import { NotificationToast } from "../../Layout/AppMain/Components/Toast";
import ConfigWS from "../../config/configWS";
window.Pusher = require('pusher-js');

const PrivateRoute = ({ component: Component, setLoading, setLoadTyper, isBlocked, setIsBlocked, isAuthenticated, setIsAuthenticated, useHere, width, notifications, updateUser, setUpdateUser, ...rest }) => (
  <LoadingOverlay tag="div" active={isBlocked}
  styles={{
    overlay: (base) => ({
      ...base,
      background: "#f1f4f6",
      color: "#000",
      position: 'fixed',
      zIndex: '99999999999999'
    }),
  }}
  spinner={false}
  text={<Row style={{textAlign: 'right'}}>
    <Col md={12}>
      <FormGroup>
        <Label>Acesso ativo em outra janela, clique em "Usar aqui" para utilizar-lo nesta janela ou clique em "Desconectar" para voltar a página de login</Label>
      </FormGroup>
    </Col>
    <Col md={12}>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={async () => {
        setIsBlocked(false);
        setLoading(true);
        await logout();
        setLoading(false);
      }} color="danger" outline={true} >Desconectar</Button>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={useHere} color="primary" >Usar aqui</Button>
    </Col>
  </Row>}
  >
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component
            {...props}
            width={width}
            setLoading={(isActive) => setLoading(isActive)}
            setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
            setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
            notifications={notifications}
            updateUser={updateUser}
            setUpdateUser={setUpdateUser}
          />
        ) : (
          isAuthenticated !== null &&
          <Redirect to="/login" />
        )
      }
    />
  </LoadingOverlay>
);

const Main = (props) => {
  const [loadActive, setLoadActive] = useState(false);
  const [loaderType, setLoadTyper] = useState('pacman');
  const [updateUser, setUpdateUser] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const userDataRef = useRef([]);
  userDataRef.current = users;
  const [isBlocked, setIsBlocked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const useHere = () => {
    let allUsers = [...userDataRef.current];
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          allUsers[i].blocked = false;
        } else {
          allUsers[i].blocked = true;
        }
      }
      setIsBlocked(false);
      setUsers([...allUsers]);

      window.Echo.join(`user_session.${user.id}`).whisper('useHere', user)
  }

  const handleClickNotification = (notification) => {
    let url = notification.url ? notification.url : `/#/notificacoes/visualiza/${notification.id}`
    window.open(url)

    if (!notification.date_viewed) {
      api.post(`notifications/${notification.id}/viewed`)
      .then(response => {
        setUser({
          ...user,
          notifications: user.notifications.map(not => {
            if (not.id === notification.id) {
              return {
                ...response.data.success
              }
            }

            return not
          })
        })
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  const handleNotify = async (n) => {
    if (document.visibilityState === 'visible') {
      NotificationToast({ title: n.title, message: n.message, onClick: () => handleClickNotification(n) })
    }

    if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
      const notification = new Notification(n.title, {
        body: n.message
      })

      notification.onclick = () => handleClickNotification(n)
    }
  }

  const requestPermissionNotification = async () => {
    if (!Notification) {
      console.log('Esse browser não suporta notificações desktop');
    } else {
      if (!['denied', 'granted'].includes(Notification.permission)) {
        // Pede ao usuário para utilizar a Notificação Desktop
        await Notification.requestPermission();
      }
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await api.get('users/me');
        let data = {
          ...getInfoUser(),
          ...response.data
        }
        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setIsAuthenticated(true);
        setUser(data);
      } catch (e) {
        console.log(e);
        setIsAuthenticated(false);
      }
    }

    getData();
  }, [updateUser]);

  useEffect(() => {
    if (user.id === undefined) {
      return;
    }

    window.Echo = new Echo(ConfigWS);
    window.Echo.join(`user_session.${user.id}`)
    .here(users => {
      setUsers(users);
    })
    .joining(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.id);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .leaving(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      setUsers(allUsers)
    })
    .listenForWhisper('useHere', u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .listen('NewNotification', r => {
      api.get('users/me')
      .then(response => {
        let data = {
          ...getInfoUser(),
          ...response.data
        }

        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setUser(data);
        handleNotify(r.notification)
      })
      .catch(e => {
        console.log(e)
      })
      api.post(`notifications/${r.notification.id}/received`)
      .catch(e => {
        console.log(e)
      })
    });
  }, [user.id, user.token]);

  useEffect(() => {
    if (isAuthenticated) {
      requestPermissionNotification()
    }
  }, [isAuthenticated])

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
        <LoadingOverlay styles={{
          overlay: (base) => ({
            ...base,
            position: 'fixed',
            zIndex: '999999999999'
          })
        }} tag="div" active={loadActive}
          spinner={<Loader active type={loaderType} />}>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              { "closed-sidebar-mobile": true,},
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}>
              <Switch>
                <Route exact path="/login" render={(props) => (
                  <LoginPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated}
                  />
                )}/>
                <Route exact path="/forgot-password" render={(props) =>
                  <ForgotPasswordPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated} />
                } />
                <Route exact path="/recovery-password/:user_id/:token" render={(props) =>
                  <RecoveryPasswordPage
                    {...props}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                    isAuthenticated={isAuthenticated} />
                } />
                <PrivateRoute path="/"
                  component={AppMain}
                  width={width}
                  setLoading={(isActive) => setLoadActive(isActive)}
                  setLoadTyper={(typeLoad) => setLoadTyper(typeLoad)}
                  setIsBlocked={(blocked) => setIsBlocked(blocked)}
                  isBlocked={isBlocked}
                  useHere={useHere}
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
                  notifications={user.notifications}
                  updateUser={updateUser}
                  setUpdateUser={setUpdateUser}
                />
              </Switch>
          </div>
          </LoadingOverlay>
          <ToastContainer />
          </>
      )}
    />
  );
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
