import Slider from "react-slick";

import bg1 from "../../../../assets/utils/images/authentication/bg1.webp";
import bg2 from "../../../../assets/utils/images/authentication/bg2.webp";
import bg3 from "../../../../assets/utils/images/authentication/bg3.webp";
import bg4 from "../../../../assets/utils/images/authentication/bg4.webp";

import { Col } from "reactstrap";

const AuthenticationSliders = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <>
      <Col lg="4" className="d-none d-lg-block">
        <div className="slider-light">
          <Slider {...settings}>
          <div className="h-100 d-flex justify-content-center align-items-center bg-woman-logo">
            <div className="slide-img-bg"
              style={{
                backgroundImage: "url(" + bg1 + ")",
              }}
            />
            <div className="slider-content">
              <h3>Atendimento ao Cliente</h3>
              <p style={{fontSize: "24px"}}>
                Oferecer a melhor experiência aos clientes é o sucesso do negócio.
              </p>
            </div>
          </div>
          <div className="h-100 d-flex justify-content-center align-items-center bg-woman">
            <div className="slide-img-bg opacity-6"
              style={{
                backgroundImage: "url(" + bg2 + ")",
              }}
            />
            <div className="slider-content">
              <h3>CRM</h3>
              <p style={{fontSize: "24px"}}>
                Possibilita a gestão de relacionamento e
                histórico do cliente.
              </p>
            </div>
          </div>
          <div className="h-100 d-flex justify-content-center align-items-center bg-man">
            <div className="slide-img-bg"
              style={{
                backgroundImage: "url(" + bg3 + ")",
              }}
            />
            <div className="slider-content">
              <h3>Kanban</h3>
              <p style={{fontSize: "24px"}}>
                Permite o controle do fluxo dos tickets para acompanhar
                o desenvolvimento dos sistemas.
              </p>
            </div>
          </div>
          <div className="h-100 d-flex justify-content-center align-items-center bg-woman2">
            <div className="slide-img-bg opacity-6"
              style={{
                backgroundImage: "url(" + bg4 + ")",
              }}
            />
            <div className="slider-content">
              <h3>Financeiro</h3>
              <p style={{fontSize: "24px"}}>
                Fornece o controle de cobrança e de
                pagamentos da empresa.
              </p>
            </div>
          </div>
          </Slider>
        </div>
      </Col>
    </>
  )
}

export default AuthenticationSliders;
