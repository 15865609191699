export const SalesNav = [
  {
    icon: "pe-7s-cash",
    label: "Vendas",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Clientes",
            to: '/#/clientes'
          },
          {
            label: "Contadores",
            to: '/#/contadores'
          },
          {
            label: "Produtos",
            to: '/#/produtos'
          },
          {
            label: "Software Houses",
            to: '/#/software_houses'
          },
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Icones (Planos de Produto)",
            to: "/#/produtos/planos/icones"
          },
          {
            label: "Contratos",
            to: "/#/contratos"
          },
          {
            label: "Assinaturas",
            to: "/#/assinaturas"
          },
          {
            label: "Nota Fiscal Produto (NFe)",
            to: "/#/notas-fiscais"
          },
          {
            label: "Nota Fiscal Consumidor (NFCe)",
            to: "/#/notas-fiscais-consumidor"
          },
        ],
      }
    ]
  }
];
export const FinancialNav = [
  {
    icon: "pe-7s-piggy",
    label: "Financeiro",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Categorias",
            to: '/#/financeiro/categoria'
          },
          {
            label: "Contas",
            to: '/#/contas'
          },
          {
            label: "Cobranças",
            to: '/#/cobrancas'
          },
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "Conciliação Bancária",
            to: '/#/conciliacao'
          },
          {
            label: "Extrato",
            to: '/#/financeiro/extrato'
          },
          {
            label: "Remessas",
            to: '/#/financeiro/remessas'
          },
          {
            label: "Retornos",
            to: '/#/financeiro/retornos'
          },
          {
            label: "Histórico Movimentações",
            to: '/#/financeiro/historico'
          },
        ]
      }
    ]
  }
];
export const SuportNav = [
  {
    icon: "pe-7s-shopbag",
    label: "Suporte",
    content: [
      {
        label: "Cadastros",
        content: [
          {
            label: "Kanbans",
            to: '/#/kanbans'
          },
          {
            label: "Tickets",
            to: '/#/tickets'
          },
          {
            label: "Sugestões",
            to: '/#/sugestoes'
          },
          {
            label: "Categorias de Tutoriais",
            to: "/#/tutoriais/categorias"
          },
          {
            label: "Tutoriais",
            to: "/#/tutoriais"
          }
        ]
      },
      {
        label: "Controle",
        content: [
          {
            label: "CRM",
            to: '/#/crm'
          },
          {
            label: "Kanbans",
            to: '/#/kanbans/visualiza'
          },
          {
            label: "SLAs",
            to: '/#/slas'
          },
          {
            label: "Notificações",
            to: '/#/notificacoes'
          },
        ]
      }
    ]
  }
];
export const ReportsNav = [
  {
    icon: "pe-7s-graph1",
    label: "Relatórios",
    content: [
      {
        label: "Desempenho",
        content: [
          {
            label: "Tickets Concluídos",
            to: '/#/relatorios/tickets-concluidos'
          },
          {
            label: "Tickets Reprovados",
            to: '/#/relatorios/tickets-reprovados'
          },
        ]
      },
      {
        label: "Assinaturas",
        content: [
          {
            label: "Assinaturas em Atraso",
            to: '/#/relatorios/assinaturas-atrasadas'
          },
          {
            label: "Assinaturas Canceladas",
            to: '/#/relatorios/assinaturas-canceladas'
          }
        ]
      }
    ]
  }
];
