const menuPermissions = () => {
  return {
    '/#/clientes': 'customers',
    '/#/contadores': 'customers',
    '/#/produtos': 'products',
    '/#/software_houses': 'software_houses',
    '/#/produtos/planos/icones': 'products',
    '/#/contratos': 'contracts',
    '/#/assinaturas': 'subscriptions',
    '/#/estoque': 'stock',
    '/#/notas-fiscais': 'nfe',
    '/#/notas-fiscais-consumidor': 'pdv',
    '/#/compras': 'purchases',
    '/#/financeiro/categoria': 'categories',
    '/#/contas': 'accounts',
    '/#/cobrancas': 'installments',
    '/#/financeiro/extrato': 'extract',
    '/#/conciliacao': 'compensates',
    '/#/financeiro/remessas': 'installments',
    '/#/financeiro/retornos': 'installments',
    '/#/financeiro/historico' : 'installments_historic',
    '/#/crm' : 'crm',
    '/#/kanbans' : 'kanbans',
    '/#/kanbans/visualiza' : 'kanban_view',
    '/#/slas' : 'slas',
    '/#/tickets' : 'tickets',
    '/#/sugestoes' : 'suggestions',
    '/#/notificacoes' : 'notifications',
    '/#/tutoriais/categorias' : 'tours',
    '/#/tutoriais' : 'tours',
    '/#/relatorios/tickets-concluidos': 'ticket_solutions',
    '/#/relatorios/tickets-reprovados': 'ticket_reproves',
    '/#/relatorios/assinaturas-canceladas': 'subscription_cancelleds',
    '/#/relatorios/assinaturas-atrasadas': 'subscription_overdues'
  }
}

export default menuPermissions;

