import React, { Fragment } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { FaRegUser } from "react-icons/fa";

import { getInfoUser, logout } from "../../../services/auth";
import { useEffect } from "react";
import { useState } from "react";

const UserBox = ({history, setLoading}) => {
  const [user, setUser] = useState({});
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setToggleDropDown(!toggleDropDown);
  }

  useEffect(() => {
    setUser(getInfoUser());
  }, []);

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{user.name}</div>
              <div className="widget-subheading">{user.email}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown toggle={handleToggleDropDown} isOpen={toggleDropDown}>
                <DropdownToggle onClick={handleToggleDropDown} className="p-0 ml-2 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle" style={{height: 44, width: 44}}>
                    <div className={`icon-wrapper-bg ${(!user || !user.logo_url) && 'bg-primary'}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    {
                      (user && user.logo_url) &&
                      <img src={user.logo_url} style={{maxWidth: 44, maxHeight: 44, margin: '0 auto'}} alt="Logo da Empresa" />
                    }
                    {
                      (!user || !user.logo_url) &&
                      <FaRegUser style={{margin: "0 auto"}} color="var(--primary)" fontSize="20px" />
                    }
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left" style={{ maxWidth: '85%' }}>
                              <div className="widget-heading">
                                {user.software_house ? user.software_house.name : 'Fuganholi Sistemas'} - {user.name}
                              </div>
                              <div className="widget-subheading opacity-8">
                              {user.email}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={async () => {
                                setLoading(true);
                                await logout();
                                setLoading(false);
                              }}>
                                Sair
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs"
                    style={{
                      height: 'auto',
                    }}>
                    <PerfectScrollbar>
                      <Nav onClick={handleToggleDropDown} vertical>
                        <NavItem>
                          <NavLink href="/#/perfil">
                            Meus Dados
                          </NavLink>
                        </NavItem>
                        {
                          user.level === 1 &&
                          <>
                            <NavItem>
                              <NavLink href="/#/usuarios">
                                Meus Usuários
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink href="http://kickidler.fuganholisistemas.com.br/" target="_blank">
                                Monitoramento Kickidler
                              </NavLink>
                            </NavItem>
                          </>
                        }
                        {
                          user.software_house_id && user.permissions.includes('software_house_profile') &&
                          <NavItem>
                            <NavLink href="/#/dados_software_house">
                              Dados da Software House
                            </NavLink>
                          </NavItem>
                        }
                        <NavItem>
                          <NavLink href="/#/calendario">
                            Calendário
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
