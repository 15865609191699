import { Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
import Loader from "react-loaders";
import cx from "classnames";
import '../../assets/layout/css/style.css';

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import menuPermissions from "../../services/menuPermissions";
import { getInfoUser } from "../../services/auth";
import { ErrorToast } from "./Components/Toast";

const Product = lazy(() => import("../../Pages/Product"));
const Customer = lazy(() => import("../../Pages/Customer"));
const Contador = lazy(() => import("../../Pages/Contador"));
const Account =  lazy(() =>  import("../../Pages/Account"));
const Installment = lazy(() => import("../../Pages/Installment"));
const PlanIcon = lazy(() => import("../../Pages/PlanIcon"));
const User =  lazy(() =>  import("../../Pages/User"));
const Contract = lazy(() => import("../../Pages/Contract"));
const Remittance =  lazy(() =>  import("../../Pages/RemittanceBank"));
const Return =  lazy(() =>  import("../../Pages/ReturnBank"));
const CRM = lazy(() => import("../../Pages/CRM"));
const Kanban = lazy(() => import("../../Pages/Kanban"));
const Sla = lazy(() => import("../../Pages/Sla"));
const Ticket = lazy(() => import("../../Pages/Ticket"));
const Suggestion = lazy(() => import("../../Pages/Suggestion"));
const Notification = lazy(() => import("../../Pages/Notification"));
const Profile =  lazy(() =>  import("../../Pages/Profile"));
const TicketSolution =  lazy(() =>  import("../../Pages/TicketSolution"));
const TicketReprove =  lazy(() =>  import("../../Pages/TicketReprove"));
const Subscription = lazy(() => import("../../Pages/Subscription"));
const SubscriptionCancelled =  lazy(() =>  import("../../Pages/SubscriptionCancelled"));
const SubscriptionOverdue =  lazy(() =>  import("../../Pages/SubscriptionOverdue"));
const Calendar =  lazy(() =>  import("../../Pages/Calendar"));
const TourCategory =  lazy(() =>  import("../../Pages/TourCategory"));
const Tour =  lazy(() =>  import("../../Pages/Tour"));
const SoftwareHouse =  lazy(() =>  import("../../Pages/SoftwareHouse"));
const SoftwareHouseProfile = lazy(() =>  import("../../Pages/SoftwareHouse/Profile"));

const AppMain = (props) => {
  const accessValidate = (route, user) => {
    let routeAdmin = ['/logs', '/usuarios', '/usuarios/cadastrar', '/meu-plano']

    if (user.level === 1) {
      return true;
    } else if (routeAdmin.includes(route)) {
      return false;
    } else if (!menuPermissions()[`/#${route}`]) {
      return true;
    } else if (user.permissions.includes(menuPermissions()[`/#${route}`])) {
      return true
    }

    return false;
  }

  useEffect(() => {
    let user = getInfoUser();
    props.history.listen((location) => {
      if (!accessValidate(location.pathname, user)) {
        ErrorToast({ placeholder: 'Você não possui permissão de acesso à essa página! Caso necessite, entre em contato com o administrador do sistema!' })
        props.history.push('/');
      }
    });
  }, []);


  return (
    <Suspense fallback={
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader show={true} type="ball-spin-fade-loader"/>
          </div>
          <h6 className="mt-5">
            Carregando...
          </h6>
        </div>
      </div>
    }>
      <AppHeader {...props} />
      <div className="app-main">
        {props.width <= 991 && <AppSidebar />}
        <div className="app-main__outer">
          <div className={cx(
            "app-main__inner",
            {"col-md-10 offset-md-1": props.width > 991}
          )}>
            <Switch>
              {/** Aqui deve ser definidas as rotas do sistema */}
              {/* <Route exact path="/" render={() => {
                return (<h1>DashBoard Sistema</h1>)
              }} /> */}
              <Route path="/dados_software_house" render={({match}) => <SoftwareHouseProfile {...props} match={match} />
                  } />
              <Route path="/produtos/planos/icones" render={({match}) => <PlanIcon {...props} match={match} />
              } />
              <Route path="/produtos" render={({match}) => <Product {...props} match={match} />
              } />
              <Route path="/software_houses" render={({match}) => <SoftwareHouse {...props} match={match} />
              } />
              <Route path="/clientes" render={({match}) => <Customer {...props} match={match} />
              } />
              <Route path="/contadores" render={({match}) => <Contador {...props} match={match} />
              } />
              <Route path="/contratos" render={({match}) => <Contract {...props} match={match} />
              } />
               <Route path="/assinaturas" render={({match}) => <Subscription {...props} match={match} />
              } />
              <Route path="/contas" render={({match}) => <Account {...props} match={match} />
              } />
              <Route path="/cobrancas" render={({match}) => <Installment {...props} match={match} />
              } />
              <Route path="/usuarios" render={({match}) => <User {...props} match={match} />
              } />
              <Route path="/financeiro/remessas" render={({match}) => <Remittance {...props} match={match} />
              } />
              <Route path="/financeiro/retornos" render={({match}) => <Return {...props} match={match} />
              } />
              <Route path="/crm" render={({match}) => <CRM {...props} match={match} />
              } />
              <Route path="/kanbans" render={({match}) => <Kanban {...props} match={match} />
              } />
              <Route path="/slas" render={({match}) => <Sla {...props} match={match} />
              } />
              <Route path="/tickets" render={({match}) => <Ticket {...props} match={match} />
              } />
              <Route path="/sugestoes" render={({match}) => <Suggestion {...props} match={match} />
              } />
              <Route path="/notificacoes" render={({match}) => <Notification {...props} match={match} />
              } />
              <Route path="/perfil" render={({match}) => <Profile {...props} match={match} />
              } />
              <Route
               path="/calendario" render={({match}) => <Calendar {...props} match={match} />
              } />
              <Route path="/tutoriais/categorias" render={({match}) => <TourCategory {...props} match={match} />
              } />
              <Route path="/tutoriais" render={({match}) => <Tour {...props} match={match} />
              } />
              <Route path="/relatorios/tickets-concluidos" render={({match}) => <TicketSolution {...props} match={match} />
              } />
              <Route path="/relatorios/tickets-reprovados" render={({match}) => <TicketReprove {...props} match={match} />
              } />
              <Route path="/relatorios/assinaturas-canceladas" render={({match}) => <SubscriptionCancelled {...props} match={match} />
              } />
              <Route path="/relatorios/assinaturas-atrasadas" render={({match}) => <SubscriptionOverdue {...props} match={match} />
              } />
              <Route render={() => <Redirect to="/crm" />} />
            </Switch>
          </div>
        </div>
      </div>
    </Suspense>
  )
};

export default AppMain;
